import { useState, useEffect } from 'react';
import { 
  Globe,
  Layers,
  ChevronLeft,
  ChevronRight,
  ArrowRight
} from 'lucide-react';
import Header from './Header';
import Footer from './Footer';
import '../styles/BackgroundAnimation.css';

const ProjectCarousel = ({ images, currentSlide, onPrevSlide, onNextSlide, onDotClick }) => (
  <div className="relative group">
    <img 
      src={images[currentSlide]?.url} 
      alt={images[currentSlide]?.alt}
      className="rounded-xl w-full h-[400px] object-cover border-2 border-gray-800"
    />
    
    <button 
      onClick={onPrevSlide}
      className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/50 p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
    >
      <ChevronLeft className="w-6 h-6 text-white" />
    </button>
    <button 
      onClick={onNextSlide}
      className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/50 p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
    >
      <ChevronRight className="w-6 h-6 text-white" />
    </button>

    <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
      {images.map((_, dotIndex) => (
        <button
          key={dotIndex}
          onClick={() => onDotClick(dotIndex)}
          className={`w-2 h-2 rounded-full transition-all ${
            currentSlide === dotIndex 
              ? 'bg-cyan-400 w-4' 
              : 'bg-gray-500'
          }`}
        />
      ))}
    </div>
  </div>
);

const ProjectDetails = ({ project }) => (
  <div className="lg:w-1/2 flex flex-col justify-center">
    <div className="text-cyan-400 text-sm mb-2">{project.type}</div>
    <h3 className="text-3xl font-bold mb-4">{project.title}</h3>
    <p className="text-gray-300 mb-6">{project.details.overview}</p>
    
    <div className="grid md:grid-cols-2 gap-8">
      <div>
        <h4 className="text-lg font-bold text-cyan-400 mb-3">Features</h4>
        <ul className="space-y-2">
          {project.details.features.map((feature, idx) => (
            <li key={idx} className="flex items-center text-gray-300">
              <div className="w-1.5 h-1.5 bg-cyan-400 rounded-full mr-2" />
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h4 className="text-lg font-bold text-cyan-400 mb-3">Technologies</h4>
        <ul className="space-y-2">
          {project.details.technologies.map((tech, idx) => (
            <li key={idx} className="flex items-center text-gray-300">
              <div className="w-1.5 h-1.5 bg-cyan-400 rounded-full mr-2" />
              {tech}
            </li>
          ))}
        </ul>
      </div>
    </div>

    <p className="text-cyan-400 mt-6">{project.details.impact}</p>
  </div>
);

export default function Portfolio() {
  const [language, setLanguage] = useState('en');
  const [currentSlides, setCurrentSlides] = useState({});
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  useEffect(() => {
    const initialSlides = content[language].projects.reduce((acc, _, index) => ({
      ...acc,
      [index]: 0
    }), {});
    setCurrentSlides(initialSlides);
  }, [language]);

  const content = {
    en: {
      hero: {
        title: "Our Success Stories",
        subtitle: "Featured Projects",
        description: "Delivering innovative digital solutions across various platforms and industries"
      },
      projects: [
        {
          title: "Coach93.com",
          type: "Cross-platform Application",
          images: [
            { url: "/api/placeholder/800/600", alt: "Coach93 Mobile App Interface" },
            { url: "/api/placeholder/800/600", alt: "Coach93 Dashboard" },
            { url: "/api/placeholder/800/600", alt: "Coach93 Training Plans" }
          ],
          details: {
            overview: "Coach93 is an innovative cross-platform application that revolutionizes sports coaching and athlete management.",
            features: [
              "Athlete performance tracking",
              "Training plan management",
              "Real-time communication",
              "Progress analytics",
              "Cross-platform synchronization"
            ],
            technologies: ["React Native", "Node.js", "MongoDB", "AWS Services"],
            impact: "Successfully serving hundreds of coaches and thousands of athletes globally"
          }
        },
        {
          title: "World Deaf Ski 2025",
          type: "Event Platform",
          images: [
            { url: "/api/placeholder/800/600", alt: "World Deaf Ski Homepage" },
            { url: "/api/placeholder/800/600", alt: "Event Registration Page" },
            { url: "/api/placeholder/800/600", alt: "Results Dashboard" }
          ],
          details: {
            overview: "A comprehensive event platform providing real-time updates and information for the World Deaf Ski Championships.",
            features: [
              "Live event updates",
              "Participant registration",
              "Results tracking",
              "Multi-language support",
              "Accessibility features"
            ],
            technologies: ["Next.js", "Tailwind CSS", "PostgreSQL", "Azure Cloud"],
            impact: "Supporting international sports community with accessible digital solutions"
          }
        },
        {
          title: "OlympSK.sk",
          type: "Sports Club Website",
          images: [
            { url: "/api/placeholder/800/600", alt: "OlympSK Homepage" },
            { url: "/api/placeholder/800/600", alt: "News Section" },
            { url: "/api/placeholder/800/600", alt: "Events Calendar" }
          ],
          details: {
            overview: "Modern and dynamic website representing Slovakia's Olympic community and activities.",
            features: [
              "Event calendar",
              "News and updates",
              "Member profiles",
              "Media gallery",
              "Resource center"
            ],
            technologies: ["React", "Node.js", "MySQL", "Cloud hosting"],
            impact: "Connecting Olympic community members and supporters across Slovakia"
          }
        }
      ]
    },
    sk: {
      hero: {
        title: "Naše Úspešné Projekty",
        subtitle: "Významné Projekty",
        description: "Poskytujeme inovatívne digitálne riešenia naprieč rôznymi platformami a odvetviami"
      },
      projects: [
        {
          title: "Coach93.com",
          type: "Multiplatformová Aplikácia",
          images: [
            { url: "/api/placeholder/800/600", alt: "Coach93 Mobilné Rozhranie" },
            { url: "/api/placeholder/800/600", alt: "Coach93 Dashboard" },
            { url: "/api/placeholder/800/600", alt: "Coach93 Tréningové Plány" }
          ],
          details: {
            overview: "Coach93 je inovatívna multiplatformová aplikácia pre športové trénovanie.",
            features: [
              "Sledovanie výkonu športovcov",
              "Správa tréningových plánov",
              "Komunikácia v reálnom čase",
              "Analytika pokroku",
              "Multiplatformová synchronizácia"
            ],
            technologies: ["React Native", "Node.js", "MongoDB", "AWS Services"],
            impact: "Úspešne slúži stovkám trénerov a tisícom športovcov"
          }
        }
      ]
    }
  };

  const nextSlide = (projectIndex) => {
    setCurrentSlides(prev => ({
      ...prev,
      [projectIndex]: (prev[projectIndex] + 1) % content[language].projects[projectIndex].images.length
    }));
  };

  const prevSlide = (projectIndex) => {
    setCurrentSlides(prev => ({
      ...prev,
      [projectIndex]: prev[projectIndex] === 0 
        ? content[language].projects[projectIndex].images.length - 1 
        : prev[projectIndex] - 1
    }));
  };

  return (
    <div className="min-h-screen bg-black text-white relative overflow-hidden" style={{ fontFamily: "'Space Mono', monospace" }}>
      <div 
        className="interactive-bg"
        style={{
          '--mouse-x': `${mousePosition.x}px`,
          '--mouse-y': `${mousePosition.y}px`
        }}
      />
      <Header language={language} setLanguage={setLanguage} />

      <header className="pt-16 md:pt-32 pb-8 md:pb-16 container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 tracking-tight bg-gradient-to-r from-cyan-400 to-purple-500 bg-clip-text text-transparent">
            {content[language].hero.title}{' '}
            <span className="text-cyan-400">{content[language].hero.subtitle}</span>
          </h2>
          <p className="text-xl text-gray-300">
            {content[language].hero.description}
          </p>
        </div>
      </header>

      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="grid gap-16">
            {content[language].projects.map((project, index) => (
              <div 
                key={index}
                className={`flex flex-col lg:flex-row gap-8 ${
                  index % 2 === 1 ? 'lg:flex-row-reverse' : ''
                }`}
              >
                <div className="lg:w-1/2 relative">
                  <ProjectCarousel 
                    images={project.images}
                    currentSlide={currentSlides[index] || 0}
                    onPrevSlide={() => prevSlide(index)}
                    onNextSlide={() => nextSlide(index)}
                    onDotClick={(dotIndex) => setCurrentSlides(prev => ({
                      ...prev,
                      [index]: dotIndex
                    }))}
                  />
                </div>
                <ProjectDetails project={project} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer language={language} />
    </div>
  );
}
