import { useState, useEffect } from 'react';
import { 
  Shield, 
  Network, 
  Cloud,
  Code,
  Settings,
  Laptop,
  Wifi,
  Printer,
  HardDrive,
  Users,
  MessageSquare
} from 'lucide-react';
import Header from './Header';
import Footer from './Footer';
import '../styles/BackgroundAnimation.css';

export default function ITServices() {
  const [language, setLanguage] = useState('en');
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const content = {
    en: {
      hero: {
        title: "Complete IT Solutions",
        subtitle: "From Setup to Security",
        description: "Whether you're setting up a new office or need advanced enterprise solutions, we've got you covered"
      },
      categories: [
        {
          title: "Office Setup & Support",
          description: "Complete IT setup and maintenance for your workplace",
          services: [
            {
              icon: Laptop,
              title: "Workstation Setup",
              description: "Complete computer setup and configuration",
              features: [
                "Hardware installation",
                "Operating system setup",
                "Software installation & licensing",
                "User account configuration",
                "Email setup and configuration"
              ]
            },
            {
              icon: Wifi,
              title: "Network Installation",
              description: "Basic network infrastructure setup",
              features: [
                "Router & switch configuration",
                "Wi-Fi network setup",
                "Network security setup",
                "Internet connection setup",
                "Basic firewall configuration"
              ]
            },
            {
              icon: Printer,
              title: "Office Equipment",
              description: "Setup and configuration of office peripherals",
              features: [
                "Printer network setup",
                "Scanner configuration",
                "VoIP phone system setup",
                "Video conferencing equipment",
                "Device sharing setup"
              ]
            },
            {
              icon: Settings,
              title: "Basic Software Setup",
              description: "Essential business software installation",
              features: [
                "Microsoft Office setup",
                "Antivirus installation",
                "Business software installation",
                "Regular updates management",
                "Software licensing management"
              ]
            }
          ]
        },
        {
          title: "Advanced IT Solutions",
          description: "Enterprise-grade technology solutions",
          services: [
            {
              icon: Shield,
              title: "Cybersecurity",
              description: "Advanced security measures and monitoring",
              features: [
                "Security audits",
                "Penetration testing",
                "Security training",
                "Incident response",
                "Security policy development"
              ]
            },
            {
              icon: Cloud,
              title: "Cloud Solutions",
              description: "Cloud infrastructure and services",
              features: [
                "Cloud migration",
                "Cloud storage setup",
                "Microsoft 365 deployment",
                "Cloud backup solutions",
                "Cloud security measures"
              ]
            },
            {
              icon: Code,
              title: "Software Development",
              description: "Custom software solutions",
              features: [
                "Web applications",
                "Mobile apps",
                "Integration solutions",
                "Database development",
                "API development"
              ]
            },
            {
              icon: Network,
              title: "Advanced Networking",
              description: "Enterprise network solutions",
              features: [
                "VPN setup",
                "VLAN configuration",
                "Network monitoring",
                "Traffic optimization",
                "Advanced firewall setup"
              ]
            }
          ]
        },
        {
          title: "Ongoing Support",
          description: "Continuous IT maintenance and assistance",
          services: [
            {
              icon: MessageSquare,
              title: "Help Desk",
              description: "Technical support for your team",
              features: [
                "Remote support",
                "On-site support",
                "User assistance",
                "Problem resolution",
                "Software guidance"
              ]
            },
            {
              icon: HardDrive,
              title: "System Maintenance",
              description: "Regular system upkeep and updates",
              features: [
                "System updates",
                "Performance optimization",
                "Security patches",
                "Hardware maintenance",
                "Regular backups"
              ]
            },
            {
              icon: Users,
              title: "User Management",
              description: "User account and access management",
              features: [
                "Account creation",
                "Permission management",
                "Password policies",
                "Access control",
                "User training"
              ]
            },
            {
              icon: Settings,
              title: "IT Consulting",
              description: "Strategic technology planning",
              features: [
                "IT strategy development",
                "Technology roadmap",
                "Budget planning",
                "Vendor management",
                "Technology assessment"
              ]
            }
          ]
        }
      ]
    },
    sk: {
      hero: {
        title: "Kompletné IT Riešenia",
        subtitle: "Od Inštalácie po Bezpečnosť",
        description: "Či už nastavujete novú kanceláriu alebo potrebujete pokročilé podnikové riešenia, sme tu pre vás"
      },
      categories: [
        {
          title: "Inštalácia a Podpora Kancelárií",
          description: "Kompletné IT nastavenie a údržba vášho pracoviska",
          services: [
            {
              icon: Laptop,
              title: "Nastavenie Pracovných Staníc",
              description: "Kompletné nastavenie a konfigurácia počítačov",
              features: [
                "Inštalácia hardvéru",
                "Nastavenie operačného systému",
                "Inštalácia a licencovanie softvéru",
                "Konfigurácia užívateľských účtov",
                "Nastavenie emailu"
              ]
            },
            {
              icon: Wifi,
              title: "Inštalácia Siete",
              description: "Základné nastavenie sieťovej infraštruktúry",
              features: [
                "Konfigurácia routerov a switchov",
                "Nastavenie Wi-Fi siete",
                "Nastavenie sieťovej bezpečnosti",
                "Nastavenie internetového pripojenia",
                "Základná konfigurácia firewallu"
              ]
            },
            {
              icon: Printer,
              title: "Kancelárske Zariadenia",
              description: "Nastavenie a konfigurácia kancelárskych zariadení",
              features: [
                "Sieťové nastavenie tlačiarní",
                "Konfigurácia skenerov",
                "Nastavenie VoIP telefónov",
                "Videokonferenčné zariadenia",
                "Nastavenie zdieľania zariadení"
              ]
            },
            {
              icon: Settings,
              title: "Základné Nastavenie Softvéru",
              description: "Inštalácia základného podnikového softvéru",
              features: [
                "Nastavenie Microsoft Office",
                "Inštalácia antivírusu",
                "Inštalácia podnikového softvéru",
                "Správa pravidelných aktualizácií",
                "Správa softvérových licencií"
              ]
            }
          ]
        },
        {
          title: "Pokročilé IT Riešenia",
          description: "Podnikové technologické riešenia",
          services: [
            {
              icon: Shield,
              title: "Kybernetická Bezpečnosť",
              description: "Pokročilé bezpečnostné opatrenia a monitoring",
              features: [
                "Bezpečnostné audity",
                "Penetračné testovanie",
                "Bezpečnostné školenia",
                "Reakcia na incidenty",
                "Vývoj bezpečnostnej politiky"
              ]
            },
            {
              icon: Cloud,
              title: "Cloudové Riešenia",
              description: "Cloudová infraštruktúra a služby",
              features: [
                "Migrácia do cloudu",
                "Nastavenie cloudového úložiska",
                "Nasadenie Microsoft 365",
                "Cloudové zálohovanie",
                "Cloudová bezpečnosť"
              ]
            },
            {
              icon: Code,
              title: "Vývoj Softvéru",
              description: "Softvérové riešenia na mieru",
              features: [
                "Webové aplikácie",
                "Mobilné aplikácie",
                "Integračné riešenia",
                "Vývoj databáz",
                "Vývoj API"
              ]
            },
            {
              icon: Network,
              title: "Pokročilé Sieťové Riešenia",
              description: "Podnikové sieťové riešenia",
              features: [
                "Nastavenie VPN",
                "Konfigurácia VLAN",
                "Monitoring siete",
                "Optimalizácia prevádzky",
                "Pokročilé nastavenie firewallu"
              ]
            }
          ]
        },
        {
          title: "Priebežná Podpora",
          description: "Kontinuálna IT údržba a asistencia",
          services: [
            {
              icon: MessageSquare,
              title: "Help Desk",
              description: "Technická podpora pre váš tím",
              features: [
                "Vzdialená podpora",
                "Podpora na mieste",
                "Používateľská asistencia",
                "Riešenie problémov",
                "Softvérové poradenstvo"
              ]
            },
            {
              icon: HardDrive,
              title: "Údržba Systémov",
              description: "Pravidelná údržba a aktualizácie systémov",
              features: [
                "Systémové aktualizácie",
                "Optimalizácia výkonu",
                "Bezpečnostné záplaty",
                "Údržba hardvéru",
                "Pravidelné zálohovanie"
              ]
            },
            {
              icon: Users,
              title: "Správa Používateľov",
              description: "Správa účtov a prístupov",
              features: [
                "Vytváranie účtov",
                "Správa oprávnení",
                "Politiky hesiel",
                "Kontrola prístupu",
                "Školenie používateľov"
              ]
            },
            {
              icon: Settings,
              title: "IT Konzultácie",
              description: "Strategické plánovanie technológií",
              features: [
                "Vývoj IT stratégie",
                "Technologický plán",
                "Plánovanie rozpočtu",
                "Správa dodávateľov",
                "Hodnotenie technológií"
              ]
            }
          ]
        }
      ]
    }
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <div className="min-h-screen bg-black text-white relative overflow-hidden" style={{ fontFamily: "'Space Mono', monospace" }}>
      <div 
        className="interactive-bg"
        style={{
          '--mouse-x': `${mousePosition.x}px`,
          '--mouse-y': `${mousePosition.y}px`
        }}
      />
      <Header language={language} setLanguage={setLanguage} />
      <div className="container mx-auto px-4 md:px-8 relative z-10">
        {/* Hero Section */}
        <header className="pt-16 md:pt-32 pb-8 md:pb-16">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-4xl md:text-5xl font-bold mb-6 tracking-tight bg-gradient-to-r from-cyan-400 to-purple-500 bg-clip-text text-transparent">
              {content[language].hero.title}{' '}
              <span className="text-cyan-400">{content[language].hero.subtitle}</span>
            </h2>
            <p className="text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto">
              {content[language].hero.description}
            </p>
          </div>
        </header>

        {/* Service Categories */}
        {content[language].categories.map((category, categoryIndex) => (
          <section 
            key={categoryIndex} 
            className={`py-16 ${categoryIndex % 2 === 1 ? '' : ''} transition-all duration-300`}
          >
            <div className="max-w-7xl mx-auto">
              <h2 className="text-5xl font-bold mb-4 bg-gradient-to-r from-cyan-400 to-purple-500 bg-clip-text text-transparent">
                {category.title}
              </h2>
              <p className="text-xl text-gray-400 mb-12 max-w-3xl">
                {category.description}
              </p>
              
              <div className="flex flex-wrap gap-8">
                {category.services.map((service, index) => {
                  const ServiceIcon = service.icon;
                  return (
                    <div 
                      key={index}
                      className="flex-1 min-w-[300px] bg-gray-900/50 backdrop-blur-sm border border-gray-800 rounded-xl p-8 
                               hover:border-cyan-400 transition-all duration-300 group
                               hover:shadow-lg hover:shadow-cyan-400/20 hover:-translate-y-1"
                    >
                      <div className="flex items-center mb-6">
                        <div className="p-3 rounded-lg bg-cyan-400/10 group-hover:bg-cyan-400/20 transition-all duration-300">
                          <ServiceIcon className="w-8 h-8 text-cyan-400" />
                        </div>
                      </div>
                      <h3 className="text-2xl font-bold mb-4 group-hover:text-cyan-400 transition-all duration-300">
                        {service.title}
                      </h3>
                      <p className="text-gray-400 mb-6 text-lg">
                        {service.description}
                      </p>
                      <ul className="space-y-3">
                        {service.features.map((feature, idx) => (
                          <li key={idx} className="flex items-center text-gray-300 group/item">
                            <div className="w-1.5 h-1.5 bg-cyan-400 rounded-full mr-3 group-hover/item:scale-125 transition-all duration-300" />
                            <span className="group-hover/item:text-cyan-400 transition-all duration-300">{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        ))}

        <Footer language={language} />
      </div>
    </div>
  );
}
