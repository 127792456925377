import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ITServicesWebsite from './components/ITServicesWebsite';
import Solutions from './components/Solutions';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import './styles/BackgroundAnimation.css';

function App() {
  return (
    <Router>
      <div className="App min-h-screen bg-black text-white">
        <main className="pt-16"> {/* Add padding top to account for fixed navigation */}
          <Routes>
            <Route path="/" element={<ITServicesWebsite />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
