import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/BackgroundAnimation.css';

const Footer = ({ language }) => {
  return (
    <footer className="animated-background py-12 mt-16">
      <div className="text-center">
        <p className="text-gray-400">Designed & Developed by Viktor Dedecek</p>
        <p className="text-gray-400"> 2024 ./BridgeIT. {language === 'en' ? 'All rights reserved.' : 'Všetky práva vyhradené.'}</p>
      </div>
    </footer>
  );
};

export default Footer;
