import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layers, Globe, Menu, X } from 'lucide-react';

const Header = ({ language, setLanguage }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    // Lock body scroll when menu is open
    document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  const isActive = (path) => {
    return location.pathname === path ? 'text-cyan-300' : 'text-cyan-400';
  };

  return (
    <>
      {/* Overlay */}
      <div
        className={`fixed inset-0 bg-black/50 transition-opacity duration-300 md:hidden ${
          isMobileMenuOpen ? 'opacity-100 z-30' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsMobileMenuOpen(false)}
      />

      <nav className="fixed top-0 left-0 right-0 z-40 bg-black/80 border-b border-cyan-900/30">
        <div className="container mx-auto flex justify-between items-center p-4">
          <Link to="/" className="flex items-center space-x-2 z-50">
            <Layers className="w-8 h-8 text-cyan-400" />
            <h1 className="text-xl font-bold text-cyan-400">./BridgeIT</h1>
          </Link>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-6">
            <Link to="/" className={`${isActive('/')} hover:text-cyan-300 transition-colors`}>
              Services
            </Link>
            <Link to="/solutions" className={`${isActive('/solutions')} hover:text-cyan-300 transition-colors`}>
              Solutions
            </Link>
            <Link to="/aboutus" className={`${isActive('/aboutus')} hover:text-cyan-300 transition-colors`}>
              About Us
            </Link>
            <Link to="/contact" className={`${isActive('/contact')} hover:text-cyan-300 transition-colors`}>
              Contact
            </Link>
            <button
              onClick={() => setLanguage(language === 'en' ? 'sk' : 'en')}
              className="flex items-center space-x-2 text-cyan-400 hover:text-cyan-300 transition-colors"
            >
              <Globe className="w-5 h-5" />
              <span>{language === 'en' ? 'SK' : 'EN'}</span>
            </button>
          </div>

          {/* Mobile Menu Button */}
          <button 
            className="md:hidden text-cyan-400 hover:text-cyan-300 p-2 z-50 transition-colors"
            onClick={toggleMobileMenu}
            aria-label="Toggle mobile menu"
          >
            {isMobileMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        <div 
          className={`fixed inset-x-0 top-1/4 transform ${isMobileMenuOpen ? 'translate-y-1/5 visible' : '-translate-y-full invisible'} bg-black/90 transition-transform duration-500 ease-in-out md:hidden z-40 flex items-center justify-center`}
        >
          <div className="flex flex-col items-center justify-center space-y-8 p-4 w-full">
            <Link 
              to="/" 
              className={`${isActive('/')} text-2xl font-medium hover:text-cyan-300 transition-colors`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Services
            </Link>
            <Link 
              to="/solutions" 
              className={`${isActive('/solutions')} text-2xl font-medium hover:text-cyan-300 transition-colors`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Solutions
            </Link>
            <Link 
              to="/aboutus" 
              className={`${isActive('/aboutus')} text-2xl font-medium hover:text-cyan-300 transition-colors`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              About Us
            </Link>
            <Link 
              to="/contact" 
              className={`${isActive('/contact')} text-2xl font-medium hover:text-cyan-300 transition-colors`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Contact
            </Link>
            <button
              onClick={() => {
                setLanguage(language === 'en' ? 'sk' : 'en');
                setIsMobileMenuOpen(false);
              }}
              className="flex items-center space-x-3 text-cyan-400 hover:text-cyan-300 text-xl transition-colors"
            >
              <Globe className="w-6 h-6" />
              <span>{language === 'en' ? 'SK' : 'EN'}</span>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;