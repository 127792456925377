import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Header from './Header';
import Footer from './Footer';
import '../styles/BackgroundAnimation.css';

const Contact = () => {
  const [language, setLanguage] = useState('en');
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    
    if (!captchaToken) {
      setFeedbackMessage('Please complete the reCAPTCHA');
      return;
    }

    setLoading(true);
    setFeedbackMessage('');
    const formData = new FormData(e.target);

    try {
      const response = await fetch('https://bridgeit.sk/api/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.get('name'),
          email: formData.get('email'),
          message: formData.get('message'),
          recaptchaToken: captchaToken
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Something went wrong');
      }

      setFeedbackMessage('Message sent successfully!');
      // Reset the reCAPTCHA
      if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
    } catch (err) {
      setFeedbackMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white relative overflow-hidden animated-background" style={{ fontFamily: "'Space Mono', monospace" }}>
      <div 
        className="interactive-bg"
        style={{
          '--mouse-x': `${mousePosition.x}px`,
          '--mouse-y': `${mousePosition.y}px`
        }}
      />
      <Header language={language} setLanguage={setLanguage} />
      <div className="container mx-auto px-4 md:px-8 relative z-10">
        <section className="pt-16 md:pt-32 pb-8 md:pb-16">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-4xl md:text-5xl font-bold mb-6 tracking-tight bg-gradient-to-r from-cyan-400 to-purple-500 bg-clip-text text-transparent">
              {language === 'en' ? 'Contact Us' : 'Kontaktujte nás'}
            </h2>
            <p className="text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto">
              {language === 'en' ? 'We would love to hear from you!' : 'Radi by sme vás počuli!'}
            </p>
          </div>
        </section>

        <section className="max-w-3xl mx-auto">
          <form className="bg-gray-900/50 backdrop-blur-sm border border-gray-800 rounded-xl p-8" onSubmit={sendEmail}>
            {loading && <p>Sending your message...</p>}
            {feedbackMessage && <p>{feedbackMessage}</p>}
            <div className="mb-4">
              <label className="block text-gray-300 mb-2" htmlFor="name">
                {language === 'en' ? 'Name' : 'Meno'}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full px-3 py-2 bg-gray-800 text-gray-300 border border-gray-700 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-300 mb-2" htmlFor="email">
                {language === 'en' ? 'Email' : 'Email'}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-3 py-2 bg-gray-800 text-gray-300 border border-gray-700 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-300 mb-2" htmlFor="message">
                {language === 'en' ? 'Message' : 'Správa'}
              </label>
              <textarea
                id="message"
                name="message"
                rows="5"
                className="w-full px-3 py-2 bg-gray-800 text-gray-300 border border-gray-700 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <ReCAPTCHA
                sitekey="6Lf4RpUqAAAAAJxOgacvskAf73V35xuhMby1iYZ-"
                onChange={handleCaptchaChange}
                theme="dark"
                hl={language === 'en' ? 'en' : 'sk'}
                size="normal"
                badge="inline"
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-cyan-400 text-black font-bold rounded hover:bg-cyan-300 transition"
            >
              {language === 'en' ? 'Send Message' : 'Odoslať správu'}
            </button>
          </form>
        </section>

        <Footer language={language} />
      </div>
    </div>
  );
};

export default Contact;
