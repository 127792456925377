import React, { useState, useEffect } from 'react';
import { 
  Shield, 
  Database, 
  Cloud,
  Code,
  Monitor,
  Network,
  Cpu,
  Lock,
  Workflow
} from 'lucide-react';
import Header from './Header';
import Footer from './Footer';
import '../styles/BackgroundAnimation.css';

const ITServicesWebsite = () => {
  const [activeService, setActiveService] = useState(null);
  const [language, setLanguage] = useState('en');
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  // Tech-inspired color palette
  const colors = {
    background: '#0A0A0A',       // Deep black
    primary: '#00F0FF',           // Bright cyan
    secondary: '#7F00FF',         // Vibrant purple
    text: '#E0E0E0',              // Light gray
    accent: '#FF00FF'             // Magenta highlight
  };

  const content = {
    en: {
      brandName: './BridgeIT',
      navigation: [
        { name: 'Services', path: '/' },
        { name: 'Solutions', path: '/solutions' },
        { name: 'About', path: '/about' },
        { name: 'Contact', path: '#' }
      ],
      headline: 'Bridge the Gap Between Technology and Business',
      subheadline: 'Innovative technology solutions engineered to propel your business into the future.',
      consultationButton: 'Initiate Consultation',
      services: [
        {
          icon: <Monitor className="w-12 h-12 text-cyan-400" />,
          title: "Web Development",
          description: "Custom web solutions tailored to your business needs.",
          details: "Implement cutting-edge server technologies, virtualization, and high-performance computing environments."
        },
        {
          icon: <Shield className="w-12 h-12 text-cyan-400" />,
          title: "Cybersecurity Frameworks",
          description: "Advanced threat protection and comprehensive security strategy.",
          details: "Multi-layered security approach including penetration testing, threat modeling, and adaptive defense mechanisms."
        },
        {
          icon: <Cloud className="w-12 h-12 text-cyan-400" />,
          title: "Cloud Engineering",
          description: "Seamless cloud migration and multi-cloud management.",
          details: "Kubernetes orchestration, cloud-native architecture design, and hybrid cloud integration strategies."
        },
        {
          icon: <Workflow className="w-12 h-12 text-cyan-400" />,
          title: "DevOps Transformation",
          description: "Streamlined development and operational workflows.",
          details: "CI/CD pipeline optimization, infrastructure as code, and automated deployment strategies."
        },
        {
          icon: <Network className="w-12 h-12 text-cyan-400" />,
          title: "Network Solutions",
          description: "Optimize your network infrastructure for peak performance.",
          details: "Implement cutting-edge server technologies, virtualization, and high-performance computing environments."
        },
        {
          icon: <Database className="w-12 h-12 text-cyan-400" />,
          title: "Data Management",
          description: "Efficient data storage and management solutions.",
          details: "Implement cutting-edge server technologies, virtualization, and high-performance computing environments."
        }
      ],
      techStackTitle: "Technologies We Leverage",
      ctaTitle: "Ready to Innovate?",
      ctaSubtitle: "Transform your technological landscape with cutting-edge solutions tailored to your unique challenges.",
      ctaButton: "Connect with Experts",
      footer: " 2024 ./BridgeIT | Advanced Technology Solutions"
    },
    sk: {
      brandName: './BridgeIT',
      navigation: [
        { name: 'Služby', path: '/' },
        { name: 'Riešenia', path: '/solutions' },
        { name: 'O nás', path: '/about' },
        { name: 'Kontakt', path: '#' }
      ],
      headline: 'Spájame Technológie a Biznis',
      subheadline: 'Inovatívne technologické riešenia navrhnuté na posunutie vašeho podnikania do budúcnosti.',
      consultationButton: 'Začať Konzultáciu',
      services: [
        {
          icon: <Monitor className="w-12 h-12 text-cyan-400" />,
          title: "Vývoj Webových Aplikácií",
          description: "Vlastné webové riešenia prispôsobené vašim potrebám.",
          details: "Implementácia špičkových serverových technológií, virtualizácie a vysokovýkonných výpočtových prostredí."
        },
        {
          icon: <Shield className="w-12 h-12 text-cyan-400" />,
          title: "Rámce Kybernetickej Bezpečnosti",
          description: "Pokročilá ochrana pred hrozbami a komplexná bezpečnostná stratégia.",
          details: "Viacvrstvový bezpečnostný prístup vrátane penetračného testovania, modelovania hrozieb a adaptívnych obranných mechanizmov."
        },
        {
          icon: <Cloud className="w-12 h-12 text-cyan-400" />,
          title: "Cloud Inžinierstvo",
          description: "Bezproblémová migrácia do cloudu a správa multi-cloud prostredí.",
          details: "Kubernetes orchestration, cloud-native architecture design, and hybrid cloud integration strategies."
        },
        {
          icon: <Workflow className="w-12 h-12 text-cyan-400" />,
          title: "DevOps Transformácia",
          description: "Zefektívnené vývojové a prevádzkové pracovné postupy.",
          details: "CI/CD pipeline optimization, infrastructure as code, and automated deployment strategies."
        },
        {
          icon: <Network className="w-12 h-12 text-cyan-400" />,
          title: "Sieťové Riešenia",
          description: "Optimalizácia vašej sieťovej infraštruktúry pre maximálny výkon.",
          details: "Implementácia špičkových serverových technológií, virtualizácie a vysokovýkonných výpočtových prostredí."
        },
        {
          icon: <Database className="w-12 h-12 text-cyan-400" />,
          title: "Správa Dát",
          description: "Efektívne riešenia pre ukladanie a správu dát.",
          details: "Implementácia špičkových serverových technológií, virtualizácie a vysokovýkonných výpočtových prostredí."
        }
      ],
      techStackTitle: "Technológie, Ktoré Využívame",
      ctaTitle: "Pripravení Inovovať?",
      ctaSubtitle: "Transformujte svoje technologické prostredie pomocou špičkových riešení prispôsobených vašim jedinečným výzvam.",
      ctaButton: "Spojiť sa s Expertmi",
      footer: " 2024 ./BridgeIT | Pokročilé Technologické Riešenia"
    }
  };

  return (
    <div className="min-h-screen bg-black text-white relative overflow-hidden" style={{ fontFamily: "'Space Mono', monospace" }}>
      <div 
        className="interactive-bg"
        style={{
          '--mouse-x': `${mousePosition.x}px`,
          '--mouse-y': `${mousePosition.y}px`
        }}
      />
      <Header 
        language={language} 
        setLanguage={setLanguage} 
        isMobileMenuOpen={isMobileMenuOpen} 
        toggleMobileMenu={toggleMobileMenu} 
      />
      <div className="container mx-auto px-8 relative z-10">

      {/* Hero Section */}
      <section className="text-center mb-8 md:mb-16 pt-16 md:pt-32">
        <h2 
          className="text-4xl md:text-5xl font-bold mb-6 tracking-tight bg-gradient-to-r from-cyan-400 to-purple-500 bg-clip-text text-transparent"
          style={{ color: colors.primary }}
        >
          {content[language].headline}
        </h2>
        <p 
          className="max-w-2xl mx-auto text-xl mb-8"
          style={{ color: colors.text }}
        >
          {content[language].subheadline}
        </p>
        <button 
          className="px-8 py-3 rounded-full text-black font-bold tracking-wider transform transition hover:scale-105"
          style={{ 
            background: `linear-gradient(to right, ${colors.primary}, ${colors.secondary})`,
            boxShadow: `0 0 15px ${colors.primary}`
          }}
          onClick={() => window.location.href='/contact'}
        >
          {content[language].consultationButton}
        </button>
      </section>

      {/* Services Grid */}
      <section className="grid md:grid-cols-2 gap-8 mb-16">
        {content[language].services.map((service, index) => {
          return (
            <div 
              key={index}
              className="border border-gray-800 p-6 rounded-lg transition-all duration-300 hover:border-opacity-100"
              style={{
                borderColor: activeService === index ? colors.primary : 'rgba(255,255,255,0.1)',
                background: 'rgba(30,30,30,0.5)'
              }}
              onMouseEnter={() => setActiveService(index)}
              onMouseLeave={() => setActiveService(null)}
            >
              <div className="flex items-center mb-4 space-x-4">
                {service.icon}
                <h3 
                  className="text-2xl font-bold"
                  style={{ color: activeService === index ? colors.primary : colors.text }}
                >
                  {service.title}
                </h3>
              </div>
              <p className="text-gray-400 mb-4">{service.description}</p>
              {activeService === index && (
                <p 
                  className="text-sm opacity-70 transition-opacity"
                  style={{ color: colors.primary }}
                >
                  {service.details}
                </p>
              )}
            </div>
          );
        })}
      </section>

      {/* Technology Stack */}
      <section className="mb-16">
        <h3 
          className="text-3xl text-center mb-10"
          style={{ color: colors.primary }}
        >
          {content[language].techStackTitle}
        </h3>
        <div className="flex justify-center space-x-10 opacity-70 hover:opacity-100 transition-opacity">
          {[Code, Database, Lock, Cpu].map((Icon, index) => (
            <Icon 
              key={index} 
              className="w-12 h-12" 
              color={colors.text}
            />
          ))}
        </div>
      </section>

      {/* Contact CTA */}
      <section 
        className="text-center py-16 rounded-lg"
        style={{ 
          background: `linear-gradient(135deg, ${colors.secondary}, ${colors.primary})`,
          boxShadow: `0 0 30px rgba(0,240,255,0.3)`
        }}
      >
        <h4 className="text-4xl font-bold mb-6 text-black">
          {content[language].ctaTitle}
        </h4>
        <p className="text-black text-xl mb-8 max-w-2xl mx-auto">
          {content[language].ctaSubtitle}
        </p>
        <button 
          className="px-10 py-4 bg-black text-white rounded-full font-bold hover:bg-gray-900 transition"
          onClick={() => window.location.href='/contact'}
        >
          {content[language].ctaButton}
        </button>
      </section>

      {/* Footer */}
      <Footer language={language} />
    </div>
    </div>
  );
};

export default ITServicesWebsite;
